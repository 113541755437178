export enum BuildBudgetPriceEnum {
  BUDGET_THRESHOLD_200K = "$200,000 +",
  BUDGET_THRESHOLD_300K = "$300,000 +",
  BUDGET_THRESHOLD_400K = "$400,000 +",
  BUDGET_THRESHOLD_500K = "$500,000 +",
  BUDGET_THRESHOLD_ANY = "Any",
}

export enum BuildBudgetPriceParseEnum {
  BUDGET_THRESHOLD_200K = 200000,
  BUDGET_THRESHOLD_300K = 300000,
  BUDGET_THRESHOLD_400K = 400000,
  BUDGET_THRESHOLD_500K = 500000,
}
